@font-face {
  font-family: "Jost-400-Regular";
  src: url("./assets/fonts/Jost-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Jost-400-Book";
  src: url("./assets/fonts/Jost-400-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Jost-400-BookItalic";
  src: url("./assets/fonts/Jost-400-BookItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Jost-500-Medium";
  src: url("./assets/fonts/Jost-500-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Jost-500-MediumItalic";
  src: url("./assets/fonts/Jost-500-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Jost-600-Semi";
  src: url("./assets/fonts/Jost-600-Semi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Jost-600-SemiItalic";
  src: url("./assets/fonts/Jost-600-SemiItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Jost-700-Bold";
  src: url("./assets/fonts/Jost-700-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Jost-700-BoldItalic";
  src: url("./assets/fonts/Jost-700-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "EBGaramond-600-SemiBold";
  src: url("./assets/fonts/EBGaramond-600-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "EBGaramond-700-Bold";
  src: url("./assets/fonts/EBGaramond-700-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Catamaran-Regular";
  src: url("./assets/fonts/Catamaran-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  /* position: fixed;
  overflow-y: hidden; */
  width: 100%;
}
.App {
  text-align: left;
  font-family: "Jost-700-Bold";
  min-height: 100vh;
  background: linear-gradient(rgb(46, 51, 56) 0%, rgb(25, 28, 31) 100%);
}
.logo {
  width: 60px;
  height: 60px;
}
.logo path,
.logo rect {
  fill: white;
}
.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.wrapper {
  width: 90%;
  min-height: 80vh;
  background-color: white;
  border-radius: 15px 15px;
  overflow-y: scroll;
}
.wallet-balance {
  color: #35d07f;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "Jost-700-Bold";
  font-size: 32px;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
}
.your-earnings {
  font-family: "Jost-400-Book";
  font-size: 21px;
  margin-bottom: 20px;
}
.apy {
  font-family: "Jost-700-Bold";
  font-size: 24px;
}
.deposited-amount {
  margin-top: 20px;
  font-family: "Jost-700-Bold";
  font-size: 18px;
  margin-bottom: 20px;
  color: darkslategrey;
}
.amount-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.amount {
  color: #35d07f;
  font-family: "Jost-700-Bold";
  font-size: 20px;
}
.amount-label {
  font-size: 21px;
  padding-right: 8px;
  font-family: "Jost-400-Book";
}
::placeholder {
  color: black;
  opacity: 0.1; /* Firefox */
}
.amount-textbox {
  text-align: right;
  width: 63px;
  border: 1px solid lightgrey;
  font-size: 21px;
  border-radius: 7px;
  padding-right: 10px;
  font-family: "Jost-400-Book";
  margin-right: 8px;
}
.amount-textbox:focus {
  outline: #35d07f solid 1px;
}

.amount-token {
  color: #35d07f;
  font-size: 20px;
}
.action-box-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.action-box {
  padding: 20px;
  border: 1px solid lightgrey;
  background-color: #f8f9f9;
  border-radius: 12px;
  /* box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 0px 16px; */
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.button {
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #3488ec;
  line-height: 20px;
  width: 120px;
  display: flex;
  font-size: 16px;
  max-width: 400px;
  font-family: "Jost-500-Medium";
  font-size: 16px;
  margin-left: 5px;
  padding-top: 10px;
  margin-right: 5px;
  border-radius: 8px;
  padding-bottom: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  text-align: center;
  margin-bottom: 12px;
}
@keyframes smooth-appear {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes smooth-disappear {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -100%;
    opacity: 0;
  }
}
.toast-notification {
  position: fixed;
  bottom: 0;
  width: 98%;
  color: black;
  font-family: "Jost-400-Book";
  background-color: white;
  min-height: 70px;
  border: 1px solid darkgrey;
  border-radius: 6px;
  bottom: -100%;
  margin-bottom: 2px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.toast-notification-close {
  height: 14px;
  display: flex;
  flex-direction: row-reverse;
}
.toast-notification-close-icon {
  margin-right: 8px;
  margin-top: 8px;
  color: black;
}

.appear {
  animation: smooth-appear 1s ease forwards;
}

.disappear {
  animation: smooth-disappear 5s ease forwards;
}
.toast-notification-message-wrapper {
  display: flext;
  flex-direction: row;
  padding: 10px;
  padding-top: 14px;
}
.toast-notification-message {
  position: relative;
  top: -2px;
  padding-left: 8px;
}
.close {
  width: 20px;
  height: 20px;
}
.disabled {
  opacity: 50%;
}
